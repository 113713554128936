import styles from './SliderBlock.module.scss'
import imageGalleryObject from '@/entities/imageGallery/interface'
import GallerySlider from '@/components/models/media/GallerySlider'

interface Props {
  gallery: imageGalleryObject
}

const SliderBlock = ({ gallery }: Props) => {
  return (
    <div className={styles.root}>
      <GallerySlider gallery={gallery} trackingStringPrefix={'cms_block:slider'} />
    </div>
  )
}

export default SliderBlock
