import GridBlock from '@/components/models/cms/blocks/GridBlock'
import HeadingBlock from '@/components/models/cms/blocks/HeadingBlock'
import TextWithIconBlock from '@/components/models/cms/blocks/TextWithIconBlock'
import TextWithImageBlock from '@/components/models/cms/blocks/TextWithImageBlock'
import { Col, Container, Row } from 'react-bootstrap'
import TextBlock from '@/components/models/cms/blocks/TextBlock'
import WysiwygBlock from '@/components/models/cms/blocks/WysiwygBlock'
import AccordionBlock from '@/components/models/cms/blocks/AccordionBlock'
import PageHeaderBlock from '@/components/models/cms/blocks/PageHeaderBlock'
import ImageBlock from '@/components/models/cms/blocks/ImageBlock'
import IssuuCatalogBlock from '@/components/models/cms/blocks/IssuuCatalogBlock'
import EmployeesBlock from '@/components/models/cms/blocks/EmployeesBlock'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelTypeObject from '@/entities/travelType/interface'
import ContactBannerBlock from '@/components/models/cms/blocks/ContactBannerBlock'
import NewsletterRegistrationBlock from '@/components/models/cms/blocks/NewsletterRegistrationBlock'
import FormBlock from '@/components/models/cms/blocks/FormBlock'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import HeaderSliderBlock from '@/components/models/cms/blocks/HeaderSliderBlock'
import FilterLeadFormBlock from '@/components/models/cms/blocks/FilterLeadFormBlock'
import TravelTypesBlock from '@/components/models/cms/blocks/TravelTypesBlock'
import BestsellerSliderBlock from '@/components/models/cms/blocks/BestsellerSliderBlock'
import PrefilteredTravelsBlock from '@/components/models/cms/blocks/PrefilteredTravelsBlock'
import DividerBlock from '@/components/models/cms/blocks/DividerBlock'
import TravelSelectionBlock from '@/components/models/cms/blocks/TravelSelectionBlock'
import IframeBlock from '@/components/models/cms/blocks/IframeBlock'
import SliderBlock from '@/components/models/cms/blocks/SliderBlock'
import DestinationsBlock from '@/components/models/travel/DestinationsBlock'
import TravelGuidesBlock from '@/components/models/cms/blocks/TravelGuidesBlock'
import BackgroundBlock from '@/components/models/cms/blocks/BackgroundBlock'

interface Props {
  type:
    | 'GRID'
    | 'HEADING'
    | 'TEXT_WITH_IMAGE'
    | 'TEXT_WITH_ICON'
    | 'TEXT'
    | 'WYSIWYG'
    | 'ACCORDION'
    | 'DIVIDER'
    | 'IFRAME'
    | 'PAGE_HEADER'
    | 'IMAGE'
    | 'SLIDER'
    | 'EMPLOYEES'
    | 'CONTACT_BANNER'
    | 'FORM'
    | 'NEWSLETTER_REGISTRATION'
    | 'ISSUU_CATALOG'
    | 'HEADER_SLIDER'
    | 'FILTER_LEAD_FORM'
    | 'PREFILTERED_TRAVELS'
    | 'TRAVEL_SELECTION'
    | 'TRAVEL_TYPES'
    | 'BESTSELLER_SLIDER'
    | 'TRAVEL_GUIDES'
    | 'DESTINATIONS'
    | 'BACKGROUND'
  block: any
  cmsConfig: cmsConfigObject
  travelTypes: travelTypeObject[]
  withGridWrap?: boolean
}
const CmsContentBlock = ({ type, block, cmsConfig, travelTypes, withGridWrap = true }: Props) => {
  let element
  let containerFluid = false
  let addCol = true
  let colPadding = true
  let GridWrap = ({ fluid, colPadding, hasCol: addCol, children }: any) => {
    return (
      <Container fluid={fluid}>
        <Row>{addCol ? <Col className={!colPadding ? 'p-0' : ''}>{children}</Col> : children}</Row>
      </Container>
    )
  }

  switch (type) {
    case 'GRID':
      element = <GridBlock data={block} cmsConfig={cmsConfig} travelTypes={travelTypes} />
      // eslint-disable-next-line react/display-name
      GridWrap = ({ children }: any) => {
        return <Container>{children}</Container>
      }
      break

    case 'PAGE_HEADER':
      withGridWrap = false
      element = <PageHeaderBlock {...block} />
      break

    case 'HEADING':
      element = <HeadingBlock {...block} />
      break

    case 'TEXT':
      element = <TextBlock {...block} />
      break

    case 'WYSIWYG':
      element = <WysiwygBlock {...block} />
      break

    case 'IMAGE':
      containerFluid = block.fluid
      colPadding = !block.fluid
      element = <ImageBlock {...block} />
      break

    case 'SLIDER':
      block.fluid = true
      containerFluid = block.fluid
      colPadding = !block.fluid
      element = <SliderBlock {...block} />
      break

    case 'TEXT_WITH_ICON':
      element = <TextWithIconBlock {...block} />
      break

    case 'TEXT_WITH_IMAGE':
      element = <TextWithImageBlock {...block} />
      break

    case 'ACCORDION':
      element = <AccordionBlock {...block} />
      break

    case 'DIVIDER':
      containerFluid = block.fluid
      colPadding = false
      element = <DividerBlock {...block} />
      break

    case 'IFRAME':
      element = <IframeBlock {...block} />
      break

    case 'EMPLOYEES':
      element = <EmployeesBlock {...block} />
      break

    case 'ISSUU_CATALOG':
      element = <IssuuCatalogBlock {...block} />
      break

    case 'CONTACT_BANNER':
      withGridWrap = false
      element = <ContactBannerBlock block={block} cmsConfig={cmsConfig} />
      break

    case 'NEWSLETTER_REGISTRATION':
      withGridWrap = false
      element = <NewsletterRegistrationBlock {...block} />
      break

    case 'FORM':
      element = (
        <GoogleReCaptchaProvider
          reCaptchaKey={'6Ld5LugeAAAAAAg2vS31OmL2neNvTcR6AXl3r3pf'}
          scriptProps={{
            async: true, // optional, default to false,
            defer: true, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <FormBlock {...block} />
        </GoogleReCaptchaProvider>
      )
      break

    case 'HEADER_SLIDER':
      withGridWrap = false
      element = <HeaderSliderBlock {...block} />
      break

    case 'FILTER_LEAD_FORM':
      withGridWrap = false
      element = <FilterLeadFormBlock {...block} />
      break

    case 'PREFILTERED_TRAVELS':
      withGridWrap = false
      element = <PrefilteredTravelsBlock {...block} />
      break

    case 'TRAVEL_SELECTION':
      withGridWrap = false
      addCol = false
      element = <TravelSelectionBlock {...block} />
      break

    case 'TRAVEL_TYPES':
      withGridWrap = false
      element = <TravelTypesBlock {...block} travelTypes={travelTypes} />
      break

    case 'BESTSELLER_SLIDER':
      withGridWrap = false
      element = <BestsellerSliderBlock />
      break

    case 'TRAVEL_GUIDES':
      // withGridWrap = false
      element = <TravelGuidesBlock {...block} />
      break

    case 'DESTINATIONS':
      withGridWrap = false
      element = <DestinationsBlock {...block} />
      break

    case 'BACKGROUND':
      withGridWrap = false
      element = <BackgroundBlock data={block} cmsConfig={cmsConfig} travelTypes={travelTypes} />
      break

    default:
      element = <>NOTHING</>
      break
  }

  if (withGridWrap) {
    return (
      <GridWrap fluid={containerFluid} colPadding={colPadding} hasCol={addCol}>
        {element}
      </GridWrap>
    )
  }
  return element
}

export default CmsContentBlock
