import cmsHeaderSliderBlockObject from '@/entities/cmsHeaderSliderBlock/interface'
import travelObject from '@/entities/travel/interface'
import { Col, Container, Row } from 'react-bootstrap'
import SliderWithDots from '@/components/elements/sliders/SliderWithDots'
import TravelSliderTeaser from '@/components/models/travel/teasers/TravelSliderTeaser'
import { isTravelOnline } from '@/lib/helpers/travel'

const getSlides = (travels: travelObject[]) => {
  let slides: Array<any> = []
  travels
    .filter((travel) => {
      return isTravelOnline(travel)
    })
    .map((travel, index: number) => {
      slides.push(<TravelSliderTeaser travel={travel} slideIndex={index} key={index} />)
    })
  return slides
}

const HeaderSliderBlock = ({ travels }: cmsHeaderSliderBlockObject) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="p-0">
            <SliderWithDots
              slides={getSlides(travels)}
              dotsPosition={'bottom'}
              autoplay={8000}
              ssr={true}
              trackingStringPrefix={'cms_block:header_slider'}
            />
          </Col>
        </Row>
      </Container>
      <style global jsx>
        {`
          main {
            margin-top: 0;
          }
        `}
      </style>
    </>
  )
}

export default HeaderSliderBlock
