import styles from './FormBlock.module.scss'
import cmsFormBlockObject from '@/entities/cmsFormBlock/interface'
import { Trans, useTranslation } from 'next-i18next'
import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import graphQLClient from '@/lib/api/graphQLClient'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { TitleH2 } from '@/components/cms/Title'
import * as yup from 'yup'
import ArrowIcon from '@/components/elements/icons/ArrowIcon'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { PrivacyPolicyPageUrlContext } from '@/app/context'
import { sendFormEvent } from '@/lib/ga/gtag'
import { useTracking } from '@/integrations/tracking/useTracking'

// language=GraphQL
const SUBMIT_CONTACT_FORM_MUTATION = `mutation SubmitContactForm($token: String!, $tenantId: ID!, $input: SubmitContactFormInput) {
  submitContactForm(token: $token, tenantId: $tenantId, input: $input) {
    success
    message
  }
}`

const schema = yup.object().shape({
  salutation: yup.string().required().oneOf(['m', 'f'], 'Select salutation'),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  message: yup.string().required(),
})
const initialValues = {
  salutation: '',
  firstName: '',
  lastName: '',
  address: '',
  zip: '',
  city: '',
  phone: '',
  email: '',
  message: '',
  requestCallback: false,
}

const FormBlock = ({ formName }: cmsFormBlockObject) => {
  const { t } = useTranslation('common')
  const PrivacyPolicyPageUrl = useContext(PrivacyPolicyPageUrlContext)

  const [state, setState] = useState<'initial' | 'success' | 'error'>('initial')
  const [errorMsg, setErrorMsg] = useState<string>(t('contactForm.submitError'))
  const [inputTracked, setInputTracked] = useState<boolean>(false)
  const { send } = useTracking()

  /** reCaptcha **/
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    return await executeRecaptcha('submitContactForm')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha])

  /** Formik **/
  const formik = useFormik<{ [x: string]: any }>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: () => {
      send.genericEvent({
        category: 'cms_block',
        object: 'contact_form',
        action: 'submit',
        data: {},
      })
      handleReCaptchaVerify().then((token) => {
        if (!token) {
          setState('error')
          return
        }
        graphQLClient
          .request(SUBMIT_CONTACT_FORM_MUTATION, { token: token, tenantId: process.env.NEXT_PUBLIC_TENANT_ID, input: formik.values })
          .then((response) => {
            formik.setSubmitting(false)
            switch (response.submitContactForm.success) {
              case true:
                sendFormEvent({ formName: 'contact' })
                setState('success')
                send.genericEvent({
                  category: 'cms_block',
                  object: 'contact_form',
                  action: 'success',
                  data: {},
                })
                break
              case false:
                setState('error')
                send.genericEvent({
                  category: 'cms_block',
                  object: 'contact_form',
                  action: 'error',
                  data: {},
                })
                switch (response.submitContactForm.message) {
                  case 'blocked by bot detection':
                    setErrorMsg(t('contactForm.submitErrorBot'))
                    break
                }
                break
            }
          })
      })
    },
  })

  useEffect(() => {
    if (!inputTracked && formik.values.firstName) {
      setInputTracked(true)
      send.genericEvent({
        category: 'cms_block',
        object: 'contact_form',
        action: 'input',
        data: {},
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.firstName])

  let content = <></>
  switch (state) {
    case 'success':
      content = (
        <>
          <Alert variant={'success'} className={'text-center mt-1 mb-3'}>
            {t('contactForm.submitSuccess')}
          </Alert>
        </>
      )
      break

    case 'error':
      content = (
        <Alert variant={'danger'} className={'text-center mt-1 mb-3'}>
          {errorMsg}
        </Alert>
      )
      break

    case 'initial':
      content = (
        <Form noValidate onSubmit={formik.handleSubmit} data-tr-view="cms_block:contact_form:view">
          <Row>
            <Col className={`${styles.leadText}`}>{t('contactForm.leadText')}</Col>
          </Row>
          <Row>
            <Form.Group className="form-group" as={Col} sm="4" controlId="contactFormSalutation">
              <Form.Control
                as="select"
                className={styles.input + ' ' + styles.select + ' form-select'}
                name="salutation"
                placeholder={`${t('contactForm.salutation')}*`}
                value={formik.values.salutation}
                onChange={formik.handleChange}
                isInvalid={formik.touched.salutation && !!formik.errors.salutation}
              >
                <option value="" disabled>
                  {t('contactForm.salutation')} *
                </option>
                <option value="m">{t('contactForm.mr')}</option>
                <option value="f">{t('contactForm.mrs')}</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" as={Col} sm="4" controlId="contactFormFirstName">
              <Form.Control
                type="text"
                name="firstName"
                placeholder={`${t('contactForm.firstName')}*`}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group" as={Col} sm="4" controlId="contactFormLastName">
              <Form.Control
                type="text"
                name="lastName"
                placeholder={`${t('contactForm.lastName')}*`}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="form-group" as={Col} sm={6} controlId="contactFormAddress">
              <Form.Control
                type="text"
                name="address"
                placeholder={`${t('contactForm.address')}`}
                value={formik.values.address}
                onChange={formik.handleChange}
                isInvalid={formik.touched.address && !!formik.errors.address}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} xs={4} sm={2} controlId="contactFormZip">
              <Form.Control
                type="text"
                name="zip"
                placeholder={`${t('contactForm.zip')}`}
                value={formik.values.zip}
                onChange={formik.handleChange}
                isInvalid={formik.touched.zip && !!formik.errors.zip}
                className={styles.input}
                autoComplete="postal-code"
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} xs={8} sm={4} controlId="contactFormCity">
              <Form.Control
                type="text"
                name="city"
                placeholder={`${t('contactForm.city')}`}
                value={formik.values.city}
                onChange={formik.handleChange}
                isInvalid={formik.touched.city && !!formik.errors.city}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="form-group" as={Col} sm={4} controlId="contactFormPhone">
              <Form.Control
                type="text"
                name="phone"
                placeholder={`${t('contactForm.phone')}`}
                value={formik.values.phone}
                onChange={formik.handleChange}
                isInvalid={formik.touched.phone && !!formik.errors.phone}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group" as={Col} sm={8} controlId="contactFormEmail">
              <Form.Control
                type="text"
                name="email"
                placeholder={`${t('contactForm.email')}*`}
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={formik.touched.email && !!formik.errors.email}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="form-group" as={Col} controlId="contactFormMessage">
              <Form.Control
                as="textarea"
                rows={5}
                name="message"
                placeholder={`${t('contactForm.message')}*`}
                value={formik.values.message}
                onChange={formik.handleChange}
                isInvalid={formik.touched.message && !!formik.errors.message}
                className={styles.input}
              />
              <Form.Control.Feedback type="invalid">* {t('form.validation.required')}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Col xs="12" className={`mb-3 mb-lg-0 ${styles.submitWrap}`}>
              <Row className="justify-content-end">
                <Col xs={12} md={'auto'} className={`${styles.requestCallbackWrap} flex-fill`}>
                  <div>
                    <Form.Check
                      type="checkbox"
                      id={`requestCallback`}
                      label={t('contactForm.requestCallback')}
                      name={'requestCallback'}
                      checked={formik.values.requestCallback}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.requestCallback && !!formik.errors.requestCallback}
                    />
                  </div>
                  <span className={styles.gtcInfo}>
                    <Trans i18nKey={'contactForm.gtcInfo'}>
                      I accept the usage of cookies and the
                      <a href={PrivacyPolicyPageUrl} target={'_blank'} rel="noreferrer">
                        privacy policy
                      </a>
                      .
                    </Trans>
                  </span>
                </Col>
                <Col xs="auto">
                  <ArrowIcon fontsize={'9rem'} className={styles.arrow} />
                </Col>
                <Col xs="auto">
                  <Button type="submit" variant={'primary'} disabled={formik.isSubmitting}>
                    {`${t('contactForm.send')}`}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )
      break
  }

  return (
    <Container className={`${styles.root} an-wobble-horizontal-trigger`}>
      <Row className={'justify-content-center'}>
        <Col md={10} xl={7}>
          <TitleH2 title={t('contactForm.contact')} className={styles.titleWrap} />
          {content}
        </Col>
      </Row>
    </Container>
  )
}

export default FormBlock
