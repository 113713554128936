import styles from './HeadingBlock.module.scss'
import cmsHeadingBlockObject from '@/entities/cmsHeadingBlock/interface'
import TitleDecoration from '@/components/cms/TitleDecoration'

const HeadingBlock = ({ importance, heading, addon, decoration }: cmsHeadingBlockObject) => {
  const HeaderTag = `h${importance}` as keyof JSX.IntrinsicElements
  const subTitleSpacing = addon !== undefined ? 'me-3' : ''
  // const overlap = false
  const mb = 'default'

  return (
    <div className={`mb-${mb} ${styles.root}`}>
      {decoration && <TitleDecoration />}
      {/*{overlap === true ? (*/}
      {/*  <>*/}
      {/*    <HeaderTag className={`mb-0 ${styles.visible} ${subTitleSpacing}`}>{heading}</HeaderTag>*/}
      {/*    <HeaderTag className={`mb-0 ${styles.positioner} ${subTitleSpacing}`}>{heading}</HeaderTag>*/}
      {/*  </>*/}
      {/*) : (*/}
      <>
        <HeaderTag className={subTitleSpacing} dangerouslySetInnerHTML={{ __html: heading }} />
      </>
      {/*)}*/}
      {addon && <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: addon }} />}
    </div>
  )
}

export default HeadingBlock
