import styles from './BackgroundBlock.module.scss'
import { Col, Container, Row } from 'react-bootstrap'
import CmsContentBlock from '@/components/models/cms/page/CmsContentBlock'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelTypeObject from '@/entities/travelType/interface'

interface Props {
  data: any
  cmsConfig: cmsConfigObject
  travelTypes: travelTypeObject[]
}

const BackgroundBlock = ({ data, cmsConfig, travelTypes }: Props) => {
  return (
    <Container fluid className={`${styles.root} bg-${data.backgroundVariant} foreground-${data.backgroundVariant}`}>
      <Row>
        <Col className={'p-0'}>
          {data.contentBlockAssignments.map((assignment: any, index: number) => {
            return <CmsContentBlock type={assignment.type} block={assignment.block} cmsConfig={cmsConfig} travelTypes={travelTypes} key={index} />
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default BackgroundBlock
