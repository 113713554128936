import styles from './TravelSliderTeaser.module.scss'
import React from 'react'
import travelObject from '@/entities/travel/interface'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import ResponsiveHeaderImage from '@/components/elements/media/ResponsiveHeaderImage'
import { buildText } from '@/components/models/travel/teasers/TravelSliderTeaser/lib'

interface Props {
  travel: travelObject
  slideIndex: number
}

const TravelSliderTeaser = ({ travel, slideIndex }: Props) => {
  const { t } = useTranslation('common')

  const text = buildText(travel, t)
  const image = travel.mainImage
  return (
    <>
      <Link href={`/tour/${travel.seoSettings.urlSlug}`} passHref>
        <a
          className={styles.slideWrap}
          data-tr-click="cms_block:header_slider_travel_teaser:click"
          data-tr-view="cms_block:header_slider_travel_teaser:view"
        >
          <div className={styles.slideInner}>
            <ResponsiveHeaderImage image={image} lazyload={slideIndex > 0} />
            <div className={styles.content}>
              <div className={styles.title} dangerouslySetInnerHTML={{ __html: travel.name }} />
              <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </a>
      </Link>
    </>
  )
}

export default TravelSliderTeaser
