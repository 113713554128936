import { getCloudinaryThemeFileUrl } from '@/integrations/cloudinary/helpers'

interface Props {
  fluid: boolean
  className?: string
}

const ContentDivider = ({ fluid, className }: Props) => {
  return (
    <>
      <div className={`divider ${className}`} />
      <style jsx>
        {`
          .divider {
            text-align: center;
            padding: 0;
            background-image: url(${getCloudinaryThemeFileUrl('images/elements/lines-short.png')});
            height: 55px;
            background-repeat: no-repeat;
            background-position: right 0 center;
            background-size: 80% 60px;

            @media (min-width: 768px) {
              background-image: url(${getCloudinaryThemeFileUrl('images/elements/lines.png')});
              background-size: ${fluid ? '100%' : '60%'} 60px;
            }
          }
        `}
      </style>
    </>
  )
}

export default ContentDivider
